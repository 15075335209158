import Http from '@/helpers/http'
import Session from '@/helpers/session'
import { useLocalStorage } from '@vueuse/core'
import Storage from '@/helpers/storage'

export default function useDynamicConstant() {
  const customer = Storage.isSet('customer') ? useLocalStorage('customer') : null
  const defaultContrat = Storage.isSet('defaultContrat') ? useLocalStorage('defaultContrat') : null

  const ROLE_GESTIONNAIRE = 9
  const ROLE_SUPER_ADMIN = 13

  const isSuperAdmin = role => role === ROLE_SUPER_ADMIN

  // Type du contrat client
  console.log('default contrat', defaultContrat)

  const TYPE_CONTRAT_CLIENT = (defaultContrat) ? JSON.parse(defaultContrat).customer_type_id : null

  // ID du contrat client
  // const CONTRAT_ID = (defaultContrat) ? JSON.parse(defaultContrat.value).id : null
  const CONTRAT_ID = localStorage.getItem('defaultContrat') !== null ? JSON.parse(localStorage.getItem('defaultContrat')).id : null

  // Nom de l'entreprise
  // const ENTERPRISE_NAME = (customer) ? JSON.parse(customer.value).name : null
  const ENTERPRISE_NAME = localStorage.getItem('customer') !== null ? JSON.parse(localStorage.getItem('customer')).name : null

  // Type de la categorie du contrat
  const TYPE_CATEGORY_CONTRAT = (defaultContrat) ? JSON.parse(defaultContrat.value).categorie.type : null

  const apiCanalAvailability = callback => {
    Http.get('get-api-status')
      .then(rep => {
        Session.set('API_CANAL_STATUS', rep.data.status)
        callback(rep.data.status)
      }).catch(error => {
        console.error(error)
      })
  }

  const htValue = (value, withTax = false, tva = false, gsHasOrrherTax = false, gsTvaWithTax = false, activatedIr = null, irValue = null, tauxDroit = null) => {
    if (parseInt(activatedIr)) {
      if (parseInt(gsHasOrrherTax) === 1 && parseInt(withTax)) {
        if (parseInt(gsTvaWithTax)) return Math.round(parseInt(value) / (1 + (parseFloat(tauxDroit) / 100) - (parseFloat(irValue) / 100)))

        return false
      }

      return Math.round((parseInt(value) / ((100 - parseFloat(irValue)) / 100)))
    }
    if (parseInt(gsHasOrrherTax) === 1 && parseInt(withTax)) {
      if (parseInt(gsTvaWithTax)) return Math.round((parseInt(value) / ((parseFloat(gsTvaWithTax) + 100) / 100)))

      return false
    }

    return Math.round((parseInt(value) / ((parseFloat(tva) + 100) / 100)))
  }

  const getDiscountAmount = (amountHt, bonusActivationParameters) => Math.ceil((((parseInt(amountHt) * parseFloat(bonusActivationParameters)) / 100)))

  const userData = JSON.parse(Session.get('userData'))

  const CURRENCY = localStorage.getItem('clientInfo') !== null ? JSON.parse(localStorage.getItem('clientInfo')).devise : 'XAF'

  console.log(CURRENCY)

  return {
    TYPE_CONTRAT_CLIENT,
    CONTRAT_ID,
    TYPE_CATEGORY_CONTRAT,
    ENTERPRISE_NAME,
    htValue,
    getDiscountAmount,
    userData,
    CURRENCY,
    apiCanalAvailability,
    ROLE_GESTIONNAIRE,
    ROLE_SUPER_ADMIN,
    isSuperAdmin,
  }
}
