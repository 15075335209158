import preset from '@/@core/preset/preset'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fortawesome/fontawesome-free/css/all.css'
import fr from 'vuetify/src/locale/fr.ts'

Vue.use(Vuetify)


Vue.component('my-component', {
  methods: {
    changeLocale () {
      this.$vuetify.lang.current = 'fr'
    },
  },
})

export default new Vuetify({
  preset,
  icons: {
    // iconfont: 'mdiSvg',
    iconfont: 'fa',
  },
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
  },
  lang: {
    locales: { fr },
    current: 'fr',
  },
})
