import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import Http from '@/helpers/http'
import app from './app'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    file_url: '',
  },
  getters: {
    getFileUrl: state => state.file_url,
  },
  mutations: {
    GET_URL_FILE: (state, payload) => {
      state.file_url = payload
    },
  },
  actions: {
    getUrlsOfFile: ({ commit }, pathUrl) => {
      console.info(pathUrl)

      return new Promise((resolve, reject) => {
        Http.post('getFileUrl', { path: pathUrl })
          .then(response => {
            commit('GET_URL_FILE', response)
            resolve()
          })
          .catch(error => {
            console.error(error)
            reject()
          })
      })
    },
  },
  modules: {
    appConfig: appConfigStoreModule,
    app,
  },
})
