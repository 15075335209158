<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4 d-xl-inline-block d-md-none"
          dot
        >
          <v-avatar
            size="30px"
            color="secondary"
            class="v-avatar-dark-bg primary--text"
          >
            {{ avatarText(userData.first_name+" "+(userData.last_name != null ?userData.last_name :"")) }}
          </v-avatar>
        </v-badge>
        <div
          class="d-none d-md-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <small class="text--white font-weight-semibold mb-n1">
            {{ userData.first_name }}
            {{ userData.last_name != null ?userData.last_name :"" }}
          </small>
          <small class="text--white">{{ Role.name }} <b>({{ userData.login }})</b></small>
        </div>
      </div>

    </template>
    <v-list>

<!--       Profile-->
      <v-list-item :to="{ name: 'apps-user-view' }">
        <v-list-item-icon class="me-2 mt-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profil</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <!-- Logout -->
      <v-list-item @click="logoutUser">
        <v-list-item-icon class="me-2 mt-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Déconnexion</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'
import { useRouter } from '@core/utils'
import { initialAbility } from '@/plugins/acl/config'
import { getCurrentInstance } from '@vue/composition-api'
import Session from '@/helpers/session'
import { avatarText } from '@core/utils/filter'
import Http from '@/helpers/http'

export default {
  setup() {
    const vm = getCurrentInstance().proxy
    const { router } = useRouter()
    const userData = JSON.parse(Session.get('userData'))
    const Role = JSON.parse(localStorage.getItem('defaultRole'))

    const logoutUser = () => {
      // Remove userData from localStorage
      // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      Http.post('/sign-out')
        .then(response => {

          Session.unset('accessToken')
          Session.unset('userData')
          localStorage.removeItem('accessToken')
          localStorage.removeItem('contrats')
          localStorage.removeItem('defaultContrat')
          localStorage.removeItem('permissions')
          localStorage.removeItem('defaultRole')
          localStorage.removeItem('menu')
          localStorage.removeItem('roles')
          localStorage.removeItem('periode')

          localStorage.clear()

          router.push({ name: 'auth-login' })

        })
        .catch(error => { console.error('Erreur :', error) })


      // Remove userData & Ability from localStorage

      // Reset ability
      // vm.$ability.update(initialAbility)

      // Redirect to login page
    }

    return {
      logoutUser,
      userData,
      Role,
      avatarText,

      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
