import { canNavigate } from '@/plugins/acl/routeProtection'
import Session from '@/helpers/session'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { ACCOUNT_PENDING } from '@/helpers/constants/global'
import dashboard from './dashboard'
import toast from '../services/toast'

Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {

    path: '/',
    redirect: to => {
      const accessToken = Session.get('accessToken')

      const contrat = localStorage.getItem('defaultContrat') !== undefined

      const isLoggedIn = accessToken != null && accessToken !== '' && accessToken !== undefined

      if (isLoggedIn && contrat) {
        const user = Session.get('userData')
        if (user === undefined) {
          localStorage.clear()

          return { name: 'auth-login', query: to.query }
        }

        const userData = JSON.parse(user)

        const { state } = userData
        const updatePassword = userData !== null ? userData.update_password : null
        console.log('password', updatePassword)
        if (updatePassword === ACCOUNT_PENDING) return { name: 'apps-user-view' }

        return { name: 'dashboard-analytics' }
      }

      return { name: 'auth-login', query: to.query }
    },
  },

  {
    path: '/reset-password/:token?',
    name: 'ResetPassword',
    component: () => import('@/views/RessetPW.vue'),
    props: route => ({
      ...route.params,
    }),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/page-test',
    name: 'page-test',
    component: () => import('@/views/test/TestView.vue'),
    meta: {
      layout: 'content',
    },
  },
  ...dashboard,
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '/login',
    component: () => import('@/views/Login.vue'),
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  const userData = Session.get('userData') !== undefined ? JSON.parse(Session.get('userData')) : null

  const state = userData !== null ? userData.state : null
  const updatePassword = userData !== null ? userData.update_password : null
  console.log('password', updatePassword)

  if (updatePassword === ACCOUNT_PENDING && to.name !== 'apps-user-view') {
    toast.error('Vous devez modifier votre mot de passe avant d\'accéder aux autres pages')
    next({ name: 'apps-user-view' })
  }

  const accessToken = Session.get('accessToken')

  const isLoggedIn = accessToken != null && accessToken !== '' && accessToken !== undefined

  console.log('Router TOKEN: ', Session.get('accessToken'))
  console.log('isLoggedIn: ', isLoggedIn)

  // eslint-disable-next-line no-constant-condition
  if (!canNavigate(to) && false) {
    // Redirect to login if not logged in
    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  if (!isLoggedIn && to.name !== 'auth-login') {
    const { pathname } = window.location
    console.log('path', pathname)
    if (!pathname.startsWith('/reset-password')) {
      console.log('/TO LOGIN')
      next('/login')
    }
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

export default router
