<template>
  <div>
    <div class="vertical-nav-header d-flex align-center justify-space-between pt-2">
        <v-row>
          <v-col cols='12' style='background: black; padding: 0px !important;'>
            <router-link
              to="/"
              class="d-flex align-center text-decoration-none"
            >
              <div class='bg-primary text-center m-0'>
              <img
                :src="require(`@/assets/images/misc/canal.png`)"
                width="55%"
                class='mt-2'
              >
            </div>
            </router-link>
          </v-col>
          <v-col cols='8' style='background: #DF2935; color: white; border-right: 1px solid #a8a5a5'>
            <v-dialog
              v-model="isDialogVisible"
              width="300"
            >
              <template #activator="{ on, attrs }">

                 <h5
                   class='text-uppercase text-center cursor-pointer ml-1'
                   style='color: white;'
                   v-on="on"
                 > {{ segment.name }} <br> ({{ segment.customer_type_name }})</h5>
              </template>

              <v-card>
                <v-card-title>
                  Sélectionnez un contrat
                </v-card-title>

                <v-divider></v-divider>

                <v-list>
                  <v-list-item-group
                    v-model="selectedItem"
                    color="primary"
                  >
                    <v-list-item
                      v-for="item in items"
                      :key="item.id"
                      @click="changeSegment(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.categorie.name"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols='4' style='background: #DF2935'></v-col>

        </v-row>

    </div>
  </div>
</template>

<script>
import {
  mdiRadioboxBlank, mdiRecordCircleOutline, mdiClose, mdiMenuDown, mdiMenu,
} from '@mdi/js'
import useAppConfig from '@core/@app-config/useAppConfig'
import themeConfig from '@themeConfig'
import {
  inject, onBeforeMount, reactive, ref,
} from '@vue/composition-api'
import Http from '@/helpers/http'
import Session from '@/helpers/session'
import useDynamicConstant from '@/helpers/useDynamicConstant'
import { PREPAYE_TYPE, POSTPAYE_TYPE } from '@/helpers/constants/contrat'

export default {
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()
    const isMouseHovered = inject('isMouseHovered')

    // const items = [{ nom: 'Hotels & Résidences meublées', id: '1' }, { nom: 'Entreprise multisites', id: '2' }]
    const selectedItem = ref(0)
    const isDialogVisible = ref(false)
    const segment = ref({})

    // let categories = reactive([])
    const segments = reactive([])
    const items = ref([])
    const myContrats = JSON.parse(localStorage.getItem('contrats'))
    const currentContrat = JSON.parse(localStorage.getItem('defaultContrat'))

    const { ENTERPRISE_NAME } = useDynamicConstant()

    const prepaye = PREPAYE_TYPE
    const postpaye = POSTPAYE_TYPE

    const nomEntreprise = ENTERPRISE_NAME

    // const nomCategorie = contrat => {
    //   const cat = categories.filter(item => item.id === contrat.categorie_id)
    //
    //   return cat[0].name
    // }

    // const getClientInfo = () => {
    //   Http.get(`get-info-from-wholesaler/${JSON.parse(localStorage.getItem('defaultContrat')).id}`)
    //     .then(response => {
    //       // eslint-disable-next-line prefer-destructuring
    //       console.log('Entreprise')
    //       console.log(response.data[0])
    //     })
    //     .catch(error => {
    //     })
    // }
    // getClientInfo()

    // eslint-disable-next-line no-unused-vars
    const loadData = () => {
      // myContrats = myContrats.filter(item => item.categorie.type !== CONTRAT_FLOTTE_TYPE)
      myContrats.forEach((item, index) => {
        // eslint-disable-next-line no-param-reassign
        if (currentContrat.id === item.id) {

          Http.get('get-customer-type/'+item.id)
            .then(response => {

              console.log(myContrats[index])
              segment.value = myContrats[index].categorie
              segment.value.customer_type_name = response.data.name
              console.log(segment)
              // window.location.reload()
            })
            .catch(error => { console.log(error) })

        }
      })

      console.log('myContrats')
      console.log(myContrats)
      // myContrats = myContrats.filter(item => item.state === 1)

      // console.log(myContrats)

      return myContrats
    }

    // const loadCategorie = () => {
    //   Http.get('categorie')
    //     .then(response => {
    //       categories = response.data
    //       items.value = loadData()
    //     })
    //     .catch(error => { console.log(error) })
    // }

    const activeContratCount = ref(0)

    const loadSegment = () => {
      Http.get('segment')
        .then(response => {
          items.value = loadData()

          // activeContratCount.value = items.value.length
          Session.set('activeContrat', activeContratCount)

          // loadCategorie()
        })
        .catch(error => { console.log(error) })
    }

    onBeforeMount(() => {
      loadSegment()
    })

    // eslint-disable-next-line no-shadow
    const changeSegment = selectedItem => {
      isDialogVisible.value = false
      if (currentContrat.id !== selectedItem.id) {

        Http.get('get-customer-type/'+selectedItem.id)
          .then(response => {
           console.log(response)
            segment.value.customer_type_name = response.data.name
            console.log(segment)

            localStorage.setItem('defaultContrat', JSON.stringify(selectedItem))
            segment.value = selectedItem.categorie

            console.log(selectedItem.categorie)

            window.location.reload()
          })
          .catch(error => { console.log(error) })

      }
    }

    const clientInfo = JSON.parse(localStorage.getItem('clientInfo'))

    return {
      menuIsVerticalNavMini,
      isMouseHovered,
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      items,
      selectedItem,
      isDialogVisible,
      segment,
      nomEntreprise,
      prepaye,
      postpaye,
      clientInfo,

      // categories,
      segments,

      // loadCategorie,
      loadSegment,
      myContrats,
      currentContrat,
      loadData,
      activeContratCount,
      changeSegment,

      // Icons
      icons: {
        mdiClose,
        mdiRadioboxBlank,
        mdiRecordCircleOutline,
        mdiMenuDown,
        mdiMenu,
      },
    }
  },
  created() {
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.2rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  //letter-spacing: 0.3px;
}

.bg-danger{
  background: blue !important;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
  //border-radius: 50%;
}

.nom-client{
  margin-top: -10px;
  text-transform: uppercase;
}
</style>
