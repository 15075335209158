<template>

    <marquee-text :duration="10" :repeat="5" :paused="paused" >
<!--      <h5>testes df dfdf dgffdgf gfg fgfgfgf gfg </h5>-->
    <div class='d-flex justify-end'  style='background: #FBD901; min-height: 25px' @mouseenter="paused = !paused" @mouseleave="paused = false">
       <h4 style='color: black' v-for="item in infos"><b class='red'>  {{ item.titre }} : </b> {{ item.contenu }} *****</h4>
    </div>
  </marquee-text>
</template>

<script>
import Vue from 'vue'
import MarqueeText from 'vue-marquee-text-component'

Vue.component('marquee-text', MarqueeText)


export default {
  setup() {
    const infos = [
      {
        titre : "Accueil",
        contenu : "Bienvenue sur SYGALIN TVSAT CORPORATE",
      }
    ]

    return {
      infos,
      paused: false,
    }
  },
}

</script>

<style scoped>
.red{
  color: rgba(220, 11, 26, 0.99);
  margin-left: 10px;
}
</style>
