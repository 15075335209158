import {
  mdiHomeOutline,
} from '@mdi/js'

/* eslint-disable */
// prettier-ignore
export default [
  { header: 'Dashboard' },
  // dashboard
  { title: 'Analytics', to: { name: 'dashboard-analytics' }, icon: mdiHomeOutline },

]
