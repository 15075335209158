<template>

  <div>
    <small
      class="ml-2"
      @click="editPeriode"
    >
      <v-icon class="mb-1">
        {{ icons.mdiCalendarOutline }}
      </v-icon>
      <span class='d-none d-md-inline-flex d-lg-inline-flex'>
         <b class="blue ml-2">{{ periode.Debut }}</b> - <b class="blue">{{ periode.Fin }}</b>
      </span>

    </small>
    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span>Modifier la période de visu</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-menu
                  v-model="periode.menu"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate(periode.Debut)"
                      label="Date de debut"
                      :rules="LocalNameRules"
                      hide-details="auto"
                      persistent-hint
                      :readonly="true"
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:append>
                        <v-icon>{{ icons.mdiCalendarMonth }}</v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="periode.Debut"
                    color="primary"
                    locale="pr-fr"
                    @change="clearDateFin(periode)"
                    @input="periode.menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="12"
              >
                <v-menu
                  v-model="periode.menu2"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Date de fin"
                      :value="formatDate(periode.Fin)"
                      :rules="LocalNameRules"
                      hide-details="auto"
                      :disabled="periode.Debut===''"
                      v-bind="attrs"
                      :readonly="true"
                      outlined
                      dense
                      v-on="on"
                    >
                      <template v-slot:append>
                        <v-icon>{{ icons.mdiCalendarMonth }}</v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    ref="dateFin"
                    v-model="periode.Fin"
                    :min="periode.Debut"
                    color="primary"
                    locale="pr-fr"
                    :disabled="periode.Debut===''"
                    @input="periode.menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="close"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            @click="save"
          >
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mdiCalendarOutline } from '@mdi/js'
import moment from 'moment/moment'
import { reactive } from '@vue/composition-api'

export default {
  setup() {

    const LocalNameRules = [
      v => !!v || 'Ce champ est réquis',
    ]
    const periode = reactive(JSON.parse(localStorage.getItem('periode')))

    const clearDateFin = el => {
      console.log(el)
      const debut = moment(el.Debut)
      console.log('clearDateFin')
      console.log(moment(el.Fin).diff(debut, 'days'))
      // eslint-disable-next-line no-param-reassign
      if (moment(el.Fin).diff(debut, 'days') < 0) el.Fin = el.Debut
    }

    const formatDate = date => moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')

    return {
      formatDate,
      icons: {
        mdiCalendarOutline,
      },
      dialog: false,
      clearDateFin,
      LocalNameRules,
      periode,
    }
  },
  created() {
    // this.getPeriode()
    moment.locale('fr')
  },
  methods: {

    editPeriode() {
      this.dialog = true
    },

    close() {
      this.dialog = false
    },

    save() {
      const sesPer = { Debut: this.periode.Debut, Fin: this.periode.Fin }
      localStorage.setItem('periode', JSON.stringify(sesPer))

      console.log('periode session', sesPer)

      window.location.reload()

      this.close()
    },

    getPeriode() {
      const sesPer = JSON.parse(localStorage.getItem('periode'))

      console.log('periode session', sesPer)

      if(sesPer !== null){
        this.periode = sesPer
      }
      console.log('periode variable', this.periode)
    },
  },
}

</script>

<style scoped>
.blue{
  color: #2366ec;
}
small{
  cursor: pointer;
}
</style>
