import axios from 'axios'
import toast from './toast'
import Session from '@/helpers/session'

// eslint-disable-next-line consistent-return
function errorResponseHandler(error) {
  // check for errorHandle config
  // eslint-disable-next-line no-prototype-builtins
  console.log(error.config)
  if (error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false) {
    return Promise.reject(error)
  }

  if (error.response !== undefined) {
    const dataError = {
      statusCode: null,
      data: null,
      message: null,
    }
    console.log('ErrorHandler')
    console.log(error.response.data)
    const resp = error.response
    const location = window.location
    switch (resp.status) {
      case 422:
        // eslint-disable-next-line no-case-declarations
        let message = ''

        // eslint-disable-next-line array-callback-return
        if (resp.data.data !== undefined) {
          Object.values(resp.data.data).map(item => {
            message = `${message}<strong class="text-h6">${item}</strong></br>`
          })

          dataError.message = message
        } else if (resp.data.errors !== undefined) {
          Object.values(resp.data.errors).map(item => {
            message = `${message}<strong class="text-h6">${item}</strong></br>`
          })
          dataError.message = message
        } else {
          dataError.message = resp.data.message
        }
        // eslint-disable-next-line no-empty,no-plusplus
        dataError.statusCode = resp.status
        dataError.data = resp.data.data
        dataError.message = message

        // dataError.message = resp.data.message
        break
      case 401:
        dataError.statusCode = resp.status
        dataError.data = resp.data
        dataError.message = resp.data.message

        if(dataError.message==="Unauthenticated."){
          Session.unset('accessToken')
          Session.unset('userData')
          localStorage.removeItem('accessToken')
          window.reload()
          toast.error("Vous avez été déconnecté")
        }

        break
      case 400:
      case 404:
      case 500:
        dataError.statusCode = resp.status
        dataError.data = resp.data
        dataError.message = resp.data.message
        break
      default:
        dataError.data = error.data
        dataError.message = 'Votre connexion internet est instable'
        break
    }
    toast.error(dataError.message)

    // return dataError
    return Promise.reject(dataError);
  } else if (error.request) {
    toast.error('Veuillez vous connecter à Internet...')
  }
}

// apply interceptor on response
axios.interceptors.response.use(
  response => response,
  errorResponseHandler,
)

export default errorResponseHandler
