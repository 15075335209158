import 'izitoast/dist/css/iziToast.min.css'
import iZtoast from 'izitoast'

const toast = {
  error: (message, title = 'Error') => iZtoast.error({
    title,
    message,
    titleColor: '#fff',
    position: 'topCenter',
    messageColor: '#fff',
    backgroundColor: '#ff4c51',
    messageSize: '16px',
  }),
  success: (message, title = 'Success') => iZtoast.success({
    title,
    message,
    position: 'topCenter',
  }),
  warning: (message, title = 'Warning') => iZtoast.warning({
    title,
    message,
    position: 'topCenter',
  }),
  info: (message, title = 'Information') => iZtoast.info({
    title,
    message,
    position: 'Center',
    timeout: 5000,
  }),
}

export default toast
