import '@/@fake-db/db'
import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import Http from '@/helpers/http'
import Storage from '@/helpers/storage'
import Session from '@/helpers/session'
import OtpInput from '@bachdgvn/vue-otp-input'
import { VueMaskDirective } from 'v-mask'
import App from './App.vue'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.directive('mask', VueMaskDirective)
Vue.component('v-otp-input', OtpInput)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  vuetify,
  Http,
  Storage,
  Session,
  render: h => h(App),
}).$mount('#app')
