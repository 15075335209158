export const PREPAYE_TYPE = 1
export const POSTPAYE_TYPE = 2

export const CACHET_DFIN_NAME = 'cach-DFIN-'
export const SIGNATURE_DFIN_NAME = 'sign-DFIN-'

export const CATEGORIE_FLOTTE = 1

// eslint-disable-next-line camelcase
export function is_category_flotte() {
  return JSON.parse(localStorage.getItem('defaultContrat')).categorie.type === CATEGORIE_FLOTTE
}

export function is_prepaye_type(type) {
  return type === PREPAYE_TYPE
}
export function isPostpayeType(type) {
  return type === POSTPAYE_TYPE
}
export function isPostpayeContrat() {
  return JSON.parse(localStorage.getItem('defaultContrat')).customer_type_id === POSTPAYE_TYPE
}
export function isPrePayeContrat() {

  return JSON.parse(localStorage.getItem('defaultContrat')).customer_type_id === PREPAYE_TYPE
}

// eslint-disable-next-line camelcase
export function get_type_char(type) {
  // eslint-disable-next-line no-nested-ternary
  return type === PREPAYE_TYPE ? 'Prépayé' : (type === POSTPAYE_TYPE ? 'Postpayé' : 'Global')
}
