const dashboard = [
  {
    path: '/dashboard',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboards/Dashboard.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/locaux/enregistrer',
    name: 'save-local',
    component: () => import('@/views/locaux/enregistrer/enregistrerLocal.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/locaux/liste/local',
    name: 'list-local',
    component: () => import('@/views/locaux/liste/listeLocal.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/user/view',
    name: 'apps-user-view',
    component: () => import('@/views/user/user-view/UserView.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/decodeurs/liste/listes-des-decodeurs-echeances',
    name: 'list-decodeur-echeance',
    component: () => import('@/views/decodeurs/liste/listeDecodeurEcheance.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/decodeurs/listeAll',
    name: 'list-all-decoder',
    component: () => import('@/views/decodeurs/liste/listeAllDecodeur.vue'),
    props: route => ({
      ...route.params,
    }),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/decodeurs/liste/:id',
    name: 'list-decodeur',
    component: () => import('@/views/decodeurs/liste/listeDecodeur.vue'),
    props: route => ({
      ...route.params,
    }),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/test/TestView',
    name: 'vue-test',
    component: () => import('@/views/test/TestView.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/operations/enregister/:operation?',
    name: 'save-operation',
    component: () => import('@/views/operations/enregistrer/enregistrerOperation.vue'),
    props: route => ({
      ...route.params,
    }),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/operations/liste/operation-encours',
    name: 'list-operation',
    component: () => import('@/views/operations/liste/listeOperation.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/operations/liste/operation-traitees',
    name: 'list-operation-treated',
    component: () => import('@/views/operations/liste/listeOperationTreated.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/operations/liste/operation-rejetees',
    name: 'list-operation-rejected',
    component: () => import('@/views/operations/liste/listeOperationRejected.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/finances/my-accounts',
    name: 'my-account-list',
    component: () => import('@/views/finances/MyAccountList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/finances/recharge',
    name: 'form-recharge',
    component: () => import('@/views/finances/recharge/AddFormRefill.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/finances/liste/recharges',
    name: 'list-recharge-all',
    component: () => import('@/views/finances/recharge/liste/ListRefillAll.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/finances/liste/recharges-encours',
    name: 'list-recharge',
    component: () => import('@/views/finances/recharge/liste/ListRefill.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/finances/liste/recharges-rejetees',
    name: 'list-recharge-rejected',
    component: () => import('@/views/finances/recharge/liste/ListRejectedRefill.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/finances/liste/recharges-traitees',
    name: 'list-recharge-treated',
    component: () => import('@/views/finances/recharge/liste/ListTreatedRefill.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/finances/etatDette/:id?',
    name: 'etat-dette',
    component: () => import('@/views/finances/paiement/etatDette.vue'),
    props: route => ({
      ...route.params,
    }),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/finances/facture/:numero',
    name: 'dette-facture',
    component: () => import('@/views/finances/paiement/DebtBill.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/finances/RemboursementDette/:billNumber',
    name: 'remboursement-dette',
    component: () => import('@/views/finances/paiement/RemboursementDette.vue'),
    props: route => ({
      ...route.params,
    }),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/finances/ListPayment/:billId?',
    name: 'liste-paiement',
    component: () => import('@/views/finances/paiement/ListPayment.vue'),
    props: route => ({
      ...route.params,
    }),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/finances/ListPayment/confirm',
    name: 'liste-paiement-confirm',
    component: () => import('@/views/finances/recharge/liste/ListTreatedRefill.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/finances/ListPayment/invalid',
    name: 'liste-paiement-invalid',
    component: () => import('@/views/finances/recharge/liste/ListRejectedRefill.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/finances/ListBills',
    name: 'liste-facture',
    component: () => import('@/views/finances/paiement/ListBills.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/caution/liste-paiements-encours',
    name: 'list-caution-encours',
    component: () => import('@/views/caution/liste/ListePaiement.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/caution/liste-paiements-traitees',
    name: 'list-caution-treated',
    component: () => import('@/views/caution/liste/ListePaiementTreated.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/caution/liste-paiements-rejetees',
    name: 'list-caution-rejected',
    component: () => import('@/views/caution/liste/ListePaiementRejected.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/caution/liste-paiements',
    name: 'list-caution-all',
    component: () => import('@/views/caution/liste/ListePaiementAll.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/caution/facture/:numero',
    name: 'caution-bill',
    component: () => import('@/views/caution/CautionBill.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/caution/proforma',
    name: 'caution-proforma',
    component: () => import('@/views/caution/CautionBill.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/dsi/AddDepartement',
    name: 'save-departement',
    component: () => import('@/views/dsi/departement/AddDepartement.vue'),
    meta: {
      layout: 'content',
    },
  },{
    path: '/dsi/ListDepartement',
    name: 'list-departement',
    component: () => import('@/views/dsi/departement/ListDepartement.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/dsi/AddPermissionCategorie',
    name: 'save-cat-permission',
    component: () => import('@/views/dsi/permission-categorie/AddPermissionCategorie.vue'),
    meta: {
      layout: 'content',
    },
  },{
    path: '/dsi/ListPermissionCategorie',
    name: 'list-cat-permission',
    component: () => import('@/views/dsi/permission-categorie/ListPermissionCategorie.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/dsi/AddPermission',
    name: 'save-permission',
    component: () => import('@/views/dsi/permission/AddPermission.vue'),
    meta: {
      layout: 'content',
    },
  },{
    path: '/dsi/ListPermission',
    name: 'list-permission',
    component: () => import('@/views/dsi/permission/ListPermission.vue'),
    meta: {
      layout: 'content',
    },
  },{
    path: '/dsi/AddRole',
    name: 'save-role',
    component: () => import('@/views/dsi/role/AddRole.vue'),
    meta: {
      layout: 'content',
    },
  },{
    path: '/dsi/ListRole',
    name: 'list-role',
    component: () => import('@/views/dsi/role/ListRole.vue'),
    meta: {
      layout: 'content',
    },
  },{
    path: '/dsi/EditRole/:id',
    name: 'edit-role',
    component: () => import('@/views/dsi/role/EditRole.vue'),
    props: route => ({
      ...route.params,
    }),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/user/UserListAddNew',
    name: 'save-user',
    component: () => import('@/views/user/user-list/UserListAddNew.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/user/UserList',
    name: 'list-user',
    component: () => import('@/views/user/user-list/UserList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/client/ClientList',
    name: 'list-client',
    component: () => import('@/views/client/ClientList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
      path: '/entrepriseAssociee/ClientFilleList',
    name: 'list-clientFille',
    component: () => import('@/views/entrepriseAssociee/ClientFilleList'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/entrepriseAssociee/ListLocalFille',
    name: 'list-emplacementFille',
    component: () => import('@/views/entrepriseAssociee/ListLocalFille'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/entrepriseAssociee/ListDecoderFille',
    name: 'list-decoderFille',
    component: () => import('@/views/entrepriseAssociee/ListDecoderFille'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/entrepriseAssociee/ListUsersFille',
    name: 'list-usersFille',
    component: () => import('@/views/entrepriseAssociee/ListUsersFille'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/entrepriseAssociee/ListBillsFille',
    name: 'list-billsFille',
    component: () => import('@/views/entrepriseAssociee/ListBillsFille'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/entrepriseAssociee/etatDetteFille',
    name: 'etat-detteFille',
    component: () => import('@/views/entrepriseAssociee/etatDetteFille'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/entrepriseAssociee/ListTreatedRefillFille',
    name: 'list-recharge-treatedFille',
    component: () => import('@/views/entrepriseAssociee/ListTreatedRefillFille'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/entrepriseAssociee/ListPaymentFille',
    name: 'liste-paiementFille',
    component: () => import('@/views/entrepriseAssociee/ListPaymentFille'),
    meta: {
      layout: 'content',
    },
  },
]

export default dashboard
